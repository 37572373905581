<template>
  <div class="flex-column pb-2 align-items-start pr-4">
    <div class="d-flex flex-column">
      <h6 class="mb-0" :for="uuid" v-html="labelHtml" />
      <small class="pl-2" v-if="nb_caracteres && rows">{{ modelValue.replace(/(\r\n\t?|\n|\r\t)/gm, "").length }}/{{ nb_caracteres * rows }} - {{ rows }} lignes de {{ nb_caracteres }} caractères</small>
      <small class="pl-2" v-else-if="nb_caracteres">{{ modelValue.replace(/(\r\n\t?|\n|\r\t)/gm, "").length }}/{{ nb_caracteres }}</small>
    </div>
      <textarea
          :value="modelValue"
          :rows="rows"
          :cols="cols"
          class="p-2"
          @change="update($event.target.value)"
          @input="update($event.target.value)"
      ></textarea>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      required: true,
      type: [String, Number]
    },
    required: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
    },
    cols: {
      type: Number,
      default: 25,
    },
    nb_caracteres: {
      type: Number
    },
    label: {
      type: String,
      default: " "
    },
    config: {
      type: Object,
      default: () => ({ type: "text" }),
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    uuid: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: "text",
    },
  },
  computed: {
    labelHtml() {
      return this.label;
    },
  },
  methods: {
    update(value) {
      this.$emit("update:modelValue", value); 
    },
  },
};
</script>