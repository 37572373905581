<template >
  <div>
    <SchemaForm schemaRowClasses="w-100" :schema="schema" :preventModelCleanupOnSchemaChange="preventModelCleanupOnSchemaChange"/>
    
    <div class="d-flex flex-row justify-content-around">
        <div class="text-center">
          <button class="btn btn-secondary" @click="cancelEntry">
            Annuler
          </button>
        </div>
        <div class="text-center">
          <button class="btn btn-primary" @click="emitSave">Sauvegarder</button>
        </div>
    </div>
  </div>
</template>

<script>
/* eslint no-unused-vars : "off" */
import { inject, ref } from "vue";
import { SchemaForm, useSchemaForm } from "formvuelate";
import { useOptionsStore } from '@/stores/options';
import { useCounterStore } from '@/stores/counter';
import { mapStores } from 'pinia';

export default {
  components: { SchemaForm },
  data() {
    return {
      content: {
        update: "0",
        urls_preview:
          "/preview/testimage.php?type=za5n&langue=FR&texte=De%20la%20part%20%0ADu%20p%C3%A8re%20No%C3%ABl%20%0APour%20Paul%20&police=13&couleur=C54BE&logo=153;;;/preview/testimage.php?type=za5n&langue=FR&texte=JOYEUX%0ANO%C3%8BL%0APAUL&police=13&couleur=C54BE&logo=157",
        mail_rupture: "",
        pack_designation_rupture: "ZA5N",
        texte: {
          1: "De la part \r\nDu père Noël \r\nPour Paul ",
          2: "JOYEUX\r\nNOËL\r\nPAUL",
        },
        categorie_logo: "6",
        logo_id: ["153", "157"],
        couleur: ["C54BE"],
        police_id: ["13", "13"],
        palette: "48",
        purchase: "",
      },
      initialContent: null,
      currentData: null
    };
  },
  props: {
    initialData: { type: Object },
    scheme: { type: Object}
  },
  setup(props) {
    Object.values(props.scheme).forEach(element => {
      if(element.schema){
        for(const [key, el] of Object.entries(element.schema)){
          if(el.condition){
            el.condition = eval(el.condition);
          }         
        }
      }
      if(element.condition){
        element.condition = eval(element.condition);
      }
    });
    const userData = ref(props.initialData);
    const schema = ref(props.scheme);
    const preventModelCleanupOnSchemaChange = props.scheme?.preventModelCleanupOnSchemaChange ?? true;
    useSchemaForm(userData);

    const formSubmit = () => {
      alert("Form submitted!");
    };

    return {
      userData,
      schema,
      preventModelCleanupOnSchemaChange,
      formSubmit
    };
  },
  mounted: function() {
    this.initialContent = JSON.stringify(this.initialData);
    this.content = this.initialData;
    this.currentData = JSON.parse(JSON.stringify(this.initialData));
    this.optionsStore.fill(this.initialData);
    for(const [key, el] of Object.entries(this.schema)){
      if(el.counter){
        this.counterStore.setCount(this.currentData[key].length);
      }         
    }
  },
  methods: {
    cancelEntry() {
      this.userData = JSON.parse(this.initialContent);
      this.currentData = JSON.parse(this.initialContent);
      this.initialContent = JSON.stringify(this.userData);
      this.optionsStore.fill(this.initialData);
      for(const [key, el] of Object.entries(this.schema)){
        if(el.counter){
          this.counterStore.setCount(this.currentData[key].length);
        }         
      }
    },
    emitSave() {
      const allInputs = document.querySelectorAll("#editPackVue form input[required]:enabled");
      for (const input of allInputs) {
        if(!input.value){
          alert("Veuillez renseigner tous les champs obligatoires");
          return;
        }
      }
      const evt = new CustomEvent("save", { detail: this.userData });
      document.querySelector("div[data-v-app]").dispatchEvent(evt);
    },
  },
  computed: {
    isDirty() {
      return this.initialContent !== JSON.stringify(this.initialData);
    },
    previewsArray() {
      return this.content.urls_preview.split(";;;");
    },
    ...mapStores(useOptionsStore),
    ...mapStores(useCounterStore)
  },
};
</script>
<style scoped src="../node_modules/bootstrap/dist/css/bootstrap.css"></style>
<style scoped>
.row {
  display: flex;
}
</style>
<style>
div[component]{
  display: flex;
  flex-wrap: wrap;
}


form > .schema-row > div[component]{
  padding: 7px 13;
  width: 100%;
  margin: 7px 0;
  background-color: #fff;
}

form > .schema-row > div[component]  h6{
  margin-left : -8px;
}

</style>
