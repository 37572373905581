<template>
  <div class="d-inline-flex flex-column pb-4 pr-2">
    <div class="d-flex flex-column">
      <h6 class="mb-0" :for="uuid" v-html="labelHtml" />
      <small class="pl-2" v-if="nb_caracteres">{{ modelValue.length }}/{{ nb_caracteres }}</small>
    </div>
    <input
      :type="type"
      :value="modelValue"
      :required="required"
      :id="uuid"
      :disabled="readOnly"
      :size="cols"
      :step="step"
      :maxlength="maxlength"
      autocomplete="off"
      @input="update($event.target.value)"
    />
    <p v-show="validation.errorMessage" v-text="validation.errorMessage"></p>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: { required: true },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: true,
      default: " "
    },
    config: {
      type: Object,
      default: () => ({ type: "text" })
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    uuid: {
      type: Number,
      default: 0
    },
    validation: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: "text"
    },
    validations: {
      type: Object,
      default: () => ({})
    },
    cols: {
      type: Number,
      default: 25
    },
    step: {
      type: Number
    },
    maxlength: {
      type: Number
    },
    nb_caracteres: {
      type: Number
    }
  },
  computed: {
    labelHtml() {
      const requiredHtml =
        this.validations._exclusive && this.validations._exclusive.required
          ? ' <span class="label--required">*</span>'
          : "";
      return this.label + requiredHtml;
    }
  },
  methods: {
    update(value) {
      this.$emit("update:modelValue", value);
    }
  }
};
</script>
